const CSS = theme => ({
    container: {
        padding: 24, 
        width: '100vw',
        margin: 0,
        justifyContent: 'center'
    },
    flush_container: {
        padding: 0,
        width: '100vw',
        height: '100vh' - 60,
        margin: 0,
        justifyContent: 'center'
    },
    statCard: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        padding: '4rem 0rem',
        width: '100%'
    },
    stat: {
        fontSize: '2.5rem',
        fontWeight: '700',
        color: '#444',
        lineHeight: 1,
        marginBottom: '0.5rem'
    },
    statDescription: {
        color: '#222',
        fontWeight: '200'
    },
    static_container: {
        marginTop: -50,
        padding: 24, 
        width: '100vw',
        justifyContent: 'center'
    },
    successMessage: {
        backgroundColor: 'green',
    },
    errorMessage: {
        backgroundColor: 'red'
    },
    accountHeader: {
        backgroundColor: '#222'
    },
    tableContainer: {
        overflowX: 'auto'
    },
    table: {
        backgroundColor: '#fff',
        minWidth: '900px'
    },
    title: {
        fontWeight: '700',
        marginBottom: '2.5px'
    },
    dashboardIcon: {
        fontSize: 50
    },
    notchedOutline: {
        [`&.Mui-focused`]: {
            borderWidth: '5px',
            borderColor: 'red !important'
        }
    },
    input: {
        backgroundColor: '#fff',
        borderRadius: '25px',
        ['& $notchedOutline']: {
            borderColor: '#ccc !important',
        },
        [`& input`]: {
            padding: '9px 10px'
        },
        [`& fieldset`]: {
            borderRadius: '25px'
        },
    },
    selectNoBackground: {
        [`&::placeholder`]: {
            color: 'red'
        },
        [`& .MuiSelect-select:focus`]: {
            backgroundColor: 'transparent'
        }
    },
    inputHighlighted: {
        backgroundColor: '#fff',
        borderRadius: '25px',
        ['& $notchedOutline']: {
            borderColor: '#f5b02e !important',
        },
        [`& input`]: {
            width: '7rem',
            padding: '9px 10px'
        },
        [`& fieldset`]: {
            borderRadius: '25px',
        },
    },
    blockInput: {
        width: '100%',
        marginBottom: '1rem',
        textAlign: 'left'
    },
    blockInputWithWhiteBackground: {
        backgroundColor: '#ffffff',
        width: '100%',
        marginTop: 5,
        marginBottom: 5,
        textAlign: 'left'
    },
    tableHeader: {
        fontWeight: '700'
    },
    center: {
        textAlign: 'center',
        width: '100%'
    },
    mapHeight: {
        [theme.breakpoints.up('xs')]: 180,
        [theme.breakpoints.up('md')]: 500        
    },
})

export default CSS;