import React from 'react';

import USAMap from "react-usa-map";
import {
    Typography
} from '@material-ui/core';
import './Map.css';


function Map() {

    const statesCustomConfig = () => {
        return {
            "GA": {
                fill: "#bbb",
                // clickHandler: (event) => console.log('Custom handler for GA', event.target.dataset)
            },
            "NY": {
                fill: "#bbb",
                // clickHandler: (event) => console.log('Custom handler for GA', event.target.dataset)
            },
            "TX": {
                fill: "#bbb"
            }
        };
    };

    return (
        <div className="map" style={{padding: '2rem'}}>
            <USAMap customize={statesCustomConfig()} width={'100%'} height={'100%'}/>
            <div style={{textAlign: 'center', marginTop: '0.5rem'}}>
                <Typography variant="caption" style={{fontFamily: 'helvetica', fontWeight: 200}}>The darker map, the stronger we are.</Typography>
            </div>
        </div>
    );
}

export default Map;
