import React, { useState } from 'react';
import looterLogo from './assets/looter_wordmark.png';

import Dashboard from './Dashboard';

import {
  Snackbar,
  Container,
  Grid,
  CircularProgress,
  TextField,
  Button
} from '@material-ui/core';

import {
  Alert
} from '@material-ui/lab';

import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCrg6JDhN6J416IL-pK0ySX7FTdLtJ9c9o",
  authDomain: "eqwityapp.firebaseapp.com",
  databaseURL: "https://eqwityapp.firebaseio.com",
  projectId: "eqwityapp",
  storageBucket: "eqwityapp.appspot.com",
  messagingSenderId: "946828859324",
  appId: "1:946828859324:web:c887f9d2f876c662"
};

firebase.initializeApp(firebaseConfig);

const AUTH = firebase.auth();
const DB = firebase.firestore();


function App() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [user, setUser] = useState({});
  const [existingUser, setExistingUser] = useState(false);
  const [scene, setScene] = useState("index");
  const [accessCode, setAccessCode] = useState("");
  // const [personalKey, setPersonalKey] = useState("");
  const [accessKey, setAccessKey] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [zipCode, setZipCode] = useState(null);
  const [securePassword, setSecurePassword] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordConfirmation, setPasswordConfirmation] = useState(null);

  const requestAccess = () => {
    setLoading(true);

    fetch('https://us-central1-eqwityapp.cloudfunctions.net/getAccessKey', {
        method: 'POST',
        header: {
            'Content-Type': 'application/json'
        },
        mode: 'cors',
        body: JSON.stringify({
            accessCode
        })
    }).then((response) => {
        if (!response.ok) {
            response.json()
            .then((data) => {
                setMessage({
                    type: "error",
                    content: data.error
                });
                setLoading(false);
            })
            .catch((error) => {
              setMessage({
                  type: "error",
                  content: error.message
              });
              setLoading(false);   
            })
        } else {
          response.json()
          .then((data) => {
            setAccessKey(data.accessKey);
            setLoading(false);
          })
          .catch((error) => {
            setMessage({
                type: "error",
                content: error.message
            });
            setLoading(false);   
          })
        }
    }).catch((error) => {
        setMessage({
            type: "error",
            content: error.message
        });
        setLoading(false);   
    })
  }

  const logIn = () => {
    if (!email || !password) {
      return setMessage({
          type: "error",
          content: "All form fields required"
      });
    }

    setLoading(true);

    AUTH.signInWithEmailAndPassword(email, password)
    .then(() => {
      setEmail(null);
      setPassword(null);
      setTimeout(() => {
        setScene("dashboard");
        setLoading(false);
      }, 1500);
    })
    .catch((error) => {
      setMessage({
        type: "error",
        content: error.message
      });
      setLoading(false);  
    })
  };

  const signUp = () => {
    if (!firstName || !lastName || !email || !zipCode || !securePassword || !passwordConfirmation || !accessKey) {
      setMessage({
          type: "error",
          content: "All form fields required"
      });
    } else if (securePassword !== passwordConfirmation) {
        setMessage({
            type: "error",
            content: "Password mismatch"
        }); 
    } else {
        setLoading(true);
        AUTH.createUserWithEmailAndPassword(email, securePassword)
        .then((newUserData) => {
            const id = newUserData.user.uid;

            DB.collection('users').doc(id).set({
                id,
                firstName,
                lastName,
                email,
                zipCode,
                createdAt: firebase.firestore.Timestamp.now()
            })
            .then(() => {
                setFirstName(null);
                setLastName(null);
                setEmail(null);
                setZipCode(null);
                setSecurePassword(null);
                setPasswordConfirmation(null);
                setAccessKey(null);
                setLoading(false);
                setScene("dashboard");
            })
            .catch((error) => {
                setLoading(false);
                setMessage({
                    type: "error",
                    content: error.message
                })
            })
        })
        .catch((error) => {
          setLoading(false);
          setMessage({
              type: "error",
              content: error.message
          });
        })
    }
  }

  return (
    <div style={{minHeight: '100vh', backgroundColor: '#222'}}>
      {scene === "index" && <Container maxWidth={false} style={{minHeight: '100vh', overflow: 'auto', backgroundColor: '#000'}}>
        <Snackbar
          ref={this}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={message !== null}
          autoHideDuration={3000}
          onClose={() => setMessage(null)}
        >
          {message && <Alert variant="filled" severity={message.type}>
              {message.content}
          </Alert>}
        </Snackbar>
        <Grid container style={{textAlign: 'center', minHeight: '100vh', paddingBottom: '4rem'}}>
          <Grid item xs={12} style={{marginBottom: '1rem'}}>
            <div style={{maxWidth: '24rem', paddingTop: '10rem', margin: "0 auto"}}>
              <div><img style={{height: '4rem'}} src={looterLogo} alt="l o o t e r  wordmark" /></div>
              <CircularProgress style={{color: '#f5f5f5', marginBottom: '1rem', opacity: loading ? 1 : 0}}/>
              {!loading && !accessKey && !existingUser && <TextField
                disabled={loading}
                size="small"
                style={{backgroundColor: '#f5f5f5', borderRadius: '4px', marginBottom: '1rem', width: '100%'}}
                onChange={(event) => setAccessCode(event.target.value)}
                label="Access Code"
                type="password"
                variant="filled"
              />}
              {/* {!loading && !accessKey && <TextField
                disabled={loading}
                size="small"
                style={{backgroundColor: '#f5f5f5', borderRadius: '4px', marginBottom: '1rem', width: '100%'}}
                onChange={(event) => setPersonalKey(event.target.value)}
                label="Personal Key"
                type="password"
                variant="filled"
              />} */}
              {!loading && !accessKey && !existingUser && <Button onClick={requestAccess} variant="contained" style={{textTransform: 'capitalize', marginBottom: '1rem'}} fullWidth>get access</Button>}
              {!loading && !accessKey && !existingUser && <Button onClick={() => setExistingUser(true)} variant="outlined" style={{textTransform: 'capitalize', color: '#f5f5f5', borderColor: '#f5f5f5'}} fullWidth>log in</Button>}
              {accessKey && !existingUser && <div>
                <TextField
                  disabled={loading}
                  size="small"
                  style={{backgroundColor: '#f5f5f5', borderRadius: '4px', marginBottom: '1rem', width: '100%'}}
                  onChange={(event) => setFirstName(event.target.value)}
                  label="First Name"
                  variant="filled"
                />
                <TextField
                  disabled={loading}
                  size="small"
                  style={{backgroundColor: '#f5f5f5', borderRadius: '4px', marginBottom: '1rem', width: '100%'}}
                  onChange={(event) => setLastName(event.target.value)}
                  label="Last Name"
                  variant="filled"
                />
                <TextField
                  disabled={loading}
                  size="small"
                  style={{backgroundColor: '#f5f5f5', borderRadius: '4px', marginBottom: '1rem', width: '100%'}}
                  onChange={(event) => setEmail(event.target.value)}
                  autoCapitalize="none"
                  label="Email"
                  type="email"
                  variant="filled"
                />
                <TextField
                  disabled={loading}
                  size="small"
                  style={{backgroundColor: '#f5f5f5', borderRadius: '4px', marginBottom: '1rem', width: '100%'}}
                  onChange={(event) => setZipCode(event.target.value)}
                  label="Zip Code"
                  type="number"
                  variant="filled"
                />
                <TextField
                  disabled={loading}
                  size="small"
                  style={{backgroundColor: '#f5f5f5', borderRadius: '4px', marginBottom: '1rem', width: '100%'}}
                  onChange={(event) => setSecurePassword(event.target.value)}
                  autoCapitalize="none"
                  label="Secure Password"
                  type="password"
                  variant="filled"
                />
                <TextField
                  disabled={loading}
                  size="small"
                  style={{backgroundColor: '#f5f5f5', borderRadius: '4px', marginBottom: '1rem', width: '100%'}}
                  onChange={(event) => setPasswordConfirmation(event.target.value)}
                  autoCapitalize="none"
                  label="Password Confirmation"
                  type="password"
                  variant="filled"
                />
                <Button disabled={loading} onClick={signUp} variant="outlined" style={{textTransform: 'capitalize', color: '#f5f5f5', borderColor: '#f5f5f5', opacity: loading ? 0.5 : 1}} fullWidth>{loading ? 'creating account...' : 'join'}</Button>
              </div>}
              {existingUser && <div>
                <TextField
                  disabled={loading}
                  size="small"
                  style={{backgroundColor: '#f5f5f5', borderRadius: '4px', marginBottom: '1rem', width: '100%'}}
                  onChange={(event) => setEmail(event.target.value)}
                  autoCapitalize="none"
                  label="Email"
                  variant="filled"
                />
                <TextField
                  disabled={loading}
                  size="small"
                  type="password"
                  style={{backgroundColor: '#f5f5f5', borderRadius: '4px', marginBottom: '1rem', width: '100%'}}
                  onChange={(event) => setPassword(event.target.value)}
                  autoCapitalize="none"
                  label="Password"
                  variant="filled"
                />
                <Button disabled={loading} onClick={logIn} variant="contained" style={{textTransform: 'capitalize', color: '#222', backgroundColor: '#f5f5f5', opacity: loading ? 0.5 : 1}} fullWidth>{loading ? 'logging in...' : 'log in'}</Button>
              </div>}
            </div>
          </Grid>
        </Grid>
      </Container>}
      {scene === "dashboard" && <Dashboard USER={user} AUTH={AUTH} DB={DB} firestore={firebase.firestore} setScene={setScene}/>}
    </div>
  );
}

export default App;
