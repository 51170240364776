import React, { Fragment, useState, useEffect } from 'react';
import looterLogo from './assets/looter_wordmark.png';
import CSS from './assets/Style';
import Map from './Map';
import NumberFormat from 'react-number-format';

import {
  Snackbar,
  SnackbarContent,
  AppBar,
  Toolbar,
  Grid,
  CircularProgress,
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Slider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  withStyles,
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  Button,
  IconButton,
  InputAdornment
} from '@material-ui/core';

import {
    AddCircleOutline,
    ThumbsUpDown
} from '@material-ui/icons';

import {
  Alert
} from '@material-ui/lab';


function Dashboard(props) {
    useEffect(() => {
        if (props.AUTH.currentUser.uid) {
            window.scrollTo(0, 0);
            getMetrics();
            getProposedActions();
            getLevers();
            getCompanies();
        } else {
            props.setScene('index');
        }
    }, []);

    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState(null);
    const [actionsLoading, setActionsLoading] = useState(true);
    const [proposedActions, setProposedActions] = useState(null);
    const [voteFormVisible, setVoteFormVisibility] = useState(false);
    const [userLeverage, setUserLeverage] = useState(false);
    const [newLeverageFormVisible, setNewLeverageFormVisibility] = useState(false);
    const [leverageLoading, setLeverageLoading] = useState(true);
    const [leverageType, setLeverageType] = useState("");
    const [leverageCategory, setLeverageCategory] = useState("");
    const [leverageCompany, setLeverageCompany] = useState("");
    const [leverageAmount, setLeverageAmount] = useState("");
    const [companies, setCompanies] = useState([]);
    const [filteredCompanies, setFilteredCompanies] = useState([]);
    const [companiesLoading, setCompaniesLoading] = useState(true);
    const [metrics, setMetrics] = useState({
        customerLeverage: {
            amount: 0
        },
        dollarLeverage: {
            amount: 0
        }
    });
    const [metricsLoading, setMetricsLoading] = useState(true);
    const [newCompanyFormVisible, setNewCompanyFormVisibility] = useState(false);
    const [newCompanyName, setNewCompanyName] = useState(null);
    const [newCompanyCategory, setNewCompanyCategory] = useState(null);
    const [newCompanyKeyMetric, setNewCompanyKeyMetric] = useState(null);
    
    const categories = [
        {name: "communications", value: "communications"},
        {name: "financial", value: "financial"},
        {name: "food + grocery", value: "food_grocery"},
        {name: "general retail", value: "retail"},
        {name: "insurance", value: "insurance"},
        {name: "media", value: "media"},
        {name: "non profit", value: "non_profit"},
        {name: "travel", value: "travel"},
        {name: "utilities", value: "utilities"},
        {name: "other", value: "other"},
    ];

    // const companiesToUpload = ([
    //     {name: "AT&T", value: "att", category: "communications", image: null, active: true, private: {customerLeverage: 222, dollarLeverage: 24642}},
    //     {name: "Spectrum", value: "Spectrum", category: "communications", image: null, active: true, private: {customerLeverage: 222, dollarLeverage: 24642}},
    //     {name: "Verizon", value: "verizon", category: "communications", image: null, active: true, private: {customerLeverage: 222, dollarLeverage: 24642}},
    //     {name: "Publix", value: "publix", category: "food_grocery", image: null, active: true, private: {customerLeverage: 222, dollarLeverage: 24642}},
    //     {name: "Walmart", value: "walmart", category: "retail", image: null, active: true, private: {customerLeverage: 222, dollarLeverage: 24642}},
    //     {name: "State Farm", value: "state_farm", category: "insurance", image: null, active: true, private: {customerLeverage: 222, dollarLeverage: 24642}},
    //     {name: "Netflix", value: "netflix", category: "media", image: null, active: true, private: {customerLeverage: 222, dollarLeverage: 24642}},
    //     {name: "Delta", value: "delta", category: "travel", image: null, active: true, private: {customerLeverage: 222, dollarLeverage: 24642}},
    //     {name: "Bounce Energy", value: "bounce_energy", image: null, category: "utilities", active: true, private: {customerLeverage: 222, dollarLeverage: 24642}},
    // ]);

    const getMetrics = () => {
        props.DB.collection('looterMetrics').get()
        .then((metricsSnapshot) => {
            let metricsData = {};
            metricsSnapshot.forEach((metric) => {
                metricsData[metric.id] = metric.data();
            })

            setMetrics(metricsData);
            setMetricsLoading(false);
        })
        .catch((error) => {
            setMessage({
                type: "error",
                content: error.message
            });

            setMetricsLoading(false);
        })
    }

    const getCompanies = () => {
        props.DB.collection('companies').orderBy('private.customerLeverage', 'desc').get()
        .then((companiesSnapshot) => {
            let companies = [];

            companiesSnapshot.forEach((companyData) => {
                let company = companyData.data();
                company.id = companyData.id;
                companies.push(company);
            });

            setCompanies(companies);
            setLoading(false);
        })
        .catch((error) => {
            setMessage({
                type: "error",
                content: error.message
            });

            setLoading(false);
        })
    };

    const getProposedActions = () => {
        props.DB.collection('actions').where('status', '==', 'proposal').get()
        .then((actionsSnapshot) => {
            let actions = [];

            actionsSnapshot.forEach((actionData) => {
                let action = actionData.data();
                action.id = actionData.id;
                actions.push(action);
            });

            setProposedActions(actions);
            setActionsLoading(false);
        })
        .catch((error) => {
            setMessage({
                type: "error",
                content: error.message
            });

            setLoading(false);
        })
    };

    const getLevers = () => {
        props.DB.collection('levers').where('userId', '==', props.AUTH.currentUser.uid).get()
        .then((leversSnapshot) => {
            let leverage = [];

            leversSnapshot.forEach((leverData) => {
                let lever = leverData.data();
                lever.id = leverData.id;
                leverage.push(lever);
            });

            setUserLeverage(leverage);
            setLeverageLoading(false);
        })
        .catch((error) => {
            setMessage({
                type: "error",
                content: error.message
            });

            setLoading(false);
        })
    };

    const addCompany = () => {
        if (!newCompanyName || !newCompanyCategory || !newCompanyKeyMetric || (newCompanyKeyMetric === "dollars" && leverageAmount < 1)) {
            return setMessage({
                type: "error",
                content: "All fields required"
            });   
        };

        setLoading(true);

        const newCompany = {
            active: true,
            category: newCompanyCategory,
            image: 'eqwity',
            keyMetric: newCompanyKeyMetric,
            name: newCompanyName,
            private: {
                customerLeverage: 0,
                dollarLeverage: 0
            },
            value: newCompanyName.toLowerCase()           
        };

        props.DB.collection('companies').add(newCompany)
        .then((companyRef) => {
            props.DB.collection('levers').add({
                keyMetric: newCompanyKeyMetric,
                category: newCompanyCategory,
                company: newCompany,
                companyName: newCompanyName,
                companyId: companyRef.id,
                amount: leverageAmount ? parseInt(leverageAmount) : 0,
                userId: props.AUTH.currentUser.uid,
                createdAt: props.firestore.Timestamp.now()
            })
            .then(() => {
                setMessage({
                    type: "success",
                    content: "Company Created and Leverage Added Successfully."
                });   
    
                getLevers();
                getCompanies();
                getMetrics();
                setLeverageType(null);
                setLeverageCategory(null);
                setLeverageCompany(null);
                setNewCompanyName(null);
                setNewCompanyCategory(null);
                setLeverageAmount("");
                setNewLeverageFormVisibility(false);
            })
            .catch((error) => {
                setMessage({
                    type: "error",
                    content: error.message
                });
    
                setLoading(false);
            })
        })
        .catch((error) => {
            setMessage({
                type: "error",
                content: error.message
            });

            setLoading(false);
        })
    }

    const addLever = () => {
        if (!leverageCategory || !leverageCompany || (leverageCompany.keyMetric === "dollars" && leverageAmount < 1)) {
            return setMessage({
                type: "error",
                content: "All fields required"
            });   
        };

        setLoading(true);
        props.DB.collection('levers').add({
            keyMetric: leverageCompany.keyMetric,
            category: leverageCategory,
            company: leverageCompany,
            companyName: leverageCompany.name,
            companyId: leverageCompany.id,
            amount: leverageAmount ? parseInt(leverageAmount) : 0,
            userId: props.AUTH.currentUser.uid,
            createdAt: props.firestore.Timestamp.now()
        })
        .then(() => {
            setMessage({
                type: "success",
                content: "Leverage added successfully."
            });   

            getLevers();
            getCompanies();
            getMetrics();
            setLeverageType(null);
            setLeverageCategory(null);
            setLeverageCompany(null);
            setNewLeverageFormVisibility(false);
        })
        .catch((error) => {
            setMessage({
                type: "error",
                content: error.message
            });

            setLoading(false);
        })
    };

    const logout = () => {
        props.AUTH.signOut()
        .then(() => {
            props.setScene('index');
        })
        .catch((error) => {
            setMessage({
                type: "error",
                content: error.message
            });            
        })
    };

    const StyledSlider = withStyles({
        root: {
            color: 'lightgreen',
            height: 12,
        },
        thumb: {
            display: 'none'
        },
        track: {
            height: 14,
            borderRadius: 4,
        },
        rail: {
            height: 14,
            borderRadius: 4,
        },
    })(Slider);

    const marks = [{value:0,label:'0'},{value: 10000,label: '10,000'}];


    return (
        <Fragment>
            <Snackbar
                ref={this}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={message !== null}
                autoHideDuration={3000}
                onClose={() => setMessage(null)}
            >
                {message && <Alert variant="filled" severity={message.type}>
                    {message.content}
                </Alert>}
            </Snackbar>
            <AppBar position="fixed" style={{background: '#000', padding: 0}}>
                <Toolbar>
                    <img alt="looter" style={{height: '2rem'}} src={looterLogo}/>
                    <div style={{flexGrow: 1}}/>
                    <Button disabled={loading} onClick={logout} style={{textTransform: 'lowercase', color: '#f5f5f5', borderColor: '#f5f5f5'}} size="small">log out</Button>
                </Toolbar>
            </AppBar>
            <Grid container item style={{paddingTop: '100px', backgroundColor: '#f5f5f5'}}>
                <Grid item xs={12} style={{paddingLeft: '10%', paddingRight: '10%', marginBottom: '2rem'}}>
                    {true && <SnackbarContent
                        message={<div><Typography style={{fontWeight: '700'}}>Step 1: <span style={{fontFamily: 'Helvetica', fontWeight: '300'}}>We need to quantify our economic leverage. Then, we can use it to influence policy and change our communities the way special interest groups and corporations do.</span></Typography></div>}
                    />}
                    <div style={{marginTop: '2rem'}}>
                        <div style={{marginBottom: '0.2rem'}}>
                            <Typography variant="caption">Customer Leverage Goal 1:</Typography>
                        </div>
                        <StyledSlider
                            defaultValue={!metricsLoading && metrics.customerLeverage ? metrics.customerLeverage.amount : 0}
                            step={null}
                            marks={marks}
                            valueLabelDisplay="auto"
                            min={0}
                            max={10000}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Map/>
                </Grid>
                <Grid item xs={12} md={4} style={{padding: '2rem'}}>
                    <div style={{marginBottom: '2rem'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Typography style={{color: '#444', fontSize: '1rem'}} variant="caption">Proposed Actions</Typography>
                            <div style={{flexGrow: 1}}/>
                            <IconButton onClick={() => setVoteFormVisibility(true)} disabled={loading}>
                                <ThumbsUpDown style={{color: '#444'}}/>
                            </IconButton>
                        </div>
                        <div style={{textAlign: 'center', margin: '1rem'}}>
                            {actionsLoading && <CircularProgress style={{color: '#222'}}/>}
                            {!actionsLoading && proposedActions.length < 1 && <Typography style={{fontFamily: 'Helvetica', fontWeight: '200', fontStyle: 'italic'}} variant="caption">no items to vote on at the moment</Typography>}
                        </div>
                    </div>
                    <div style={{marginBottom: '2rem'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Typography style={{color: '#444', fontSize: '1rem'}} variant="caption">My Leverage</Typography>
                            <div style={{flexGrow: 1}}/>
                            <IconButton onClick={() => setNewLeverageFormVisibility(true)} disabled={loading}>
                                <AddCircleOutline style={{color: '#444'}}/>
                            </IconButton>
                        </div>
                        <div style={{textAlign: 'center', paddingTop: '0.5rem'}}>
                            {leverageLoading && <CircularProgress style={{color: '#222'}}/>}
                            {!leverageLoading && userLeverage.length < 1 && <Typography style={{fontFamily: 'Helvetica', fontWeight: '200', fontStyle: 'italic'}} variant="caption">you haven't added any leverage</Typography>}
                            {!leverageLoading && userLeverage.length > 0 && userLeverage.map((lever) => {
                                return (
                                    <Card key={lever.id} style={{marginBottom: '0.5rem'}}>
                                        <CardContent style={{textAlign: 'left', padding: '0.25rem 0.5rem'}}>
                                            <img alt={lever.company.name} style={{height: '2rem', verticalAlign: 'middle', marginRight: '0.25rem'}} src={lever.company.image === 'eqwity' ? 'https://firebasestorage.googleapis.com/v0/b/eqwityapp.appspot.com/o/logos%2Feqwity.png?alt=media&token=943b6d12-1eee-4806-9e35-5cb222e43456' : `https://firebasestorage.googleapis.com/v0/b/eqwityapp.appspot.com/o/logos%2F${lever.company.value}.png?alt=media&token=bb447700-0df9-4177-8719-ef64e64d70f4`}/> <Typography style={{display: 'inline', textAlign: 'left', fontFamily: 'helvetica', fontWeight: '200'}}><b>{lever.companyName}</b> <span style={{fontSize: '0.8rem'}}>{lever.keyMetric === "dollars" ? '(recurring)' : '(frequented)'}</span></Typography>
                                        </CardContent>
                                    </Card>
                                )
                            })}
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card style={{backgroundColor: '#000', margin: '0', borderRadius: 0, border: '0 solid #fff', borderBottomWidth: '1px', borderRightWidth: '1px'}}>
                        <CardContent style={{textAlign: 'center'}}>
                            {metricsLoading && <CircularProgress style={{color: '#f5f5f5'}}/>}
                            {!metricsLoading && metrics && <Typography style={{color: '#f5f5f5', fontFamily: 'helvetica', fontSize: '2.25rem', fontWeight: '100'}}>
                                <NumberFormat displayType="text" value={metrics.customerLeverage && metrics.customerLeverage.amount} thousandSeparator={true}/>
                            </Typography>}
                            <div>
                                <Typography style={{color: '#f5f5f5', fontSize: '1rem'}} variant="caption">
                                    leverage <em>(customers)</em>
                                </Typography>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card style={{backgroundColor: '#000', margin: '0', borderRadius: 0, border: '0 solid #fff', borderBottomWidth: '1px'}}>
                        <CardContent style={{textAlign: 'center'}}>
                            {metricsLoading && <CircularProgress style={{color: '#f5f5f5'}}/>}
                            {!metricsLoading && metrics && <Typography style={{color: '#f5f5f5', fontFamily: 'helvetica', fontSize: '2.25rem', fontWeight: '100'}}>
                                <NumberFormat displayType="text" value={metrics.dollarLeverage && metrics.dollarLeverage.amount} thousandSeparator={true} prefix={'$'}/>
                            </Typography>}
                            <div>
                                <Typography style={{color: '#f5f5f5', fontSize: '1rem'}} variant="caption">
                                    leverage <em>(dollars/yr)</em>
                                </Typography>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} style={{margin: '0', backgroundColor: '#444', textAlign: 'center', padding: '0.5rem', border: '0 solid #fff', borderBottomWidth: '1px'}}>
                    <Typography style={{color: '#f5f5f5'}} variant="caption">Highest Leverage Concentration</Typography>
                </Grid>
                <Grid item xs={12} style={{margin: '0', backgroundColor: '#f5f5f5', overflow: 'scroll'}}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>COMPANIES</TableCell>
                                <TableCell></TableCell>
                                <TableCell align="right">CUSTOMERS</TableCell>
                                <TableCell align="right">DOLLARS (per year)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {companies && companies.map((company) => {return (<TableRow key={company.id}>
                                <TableCell style={{fontFamily: 'helvetica', fontWeight: '100', fontSize: '1.25rem'}}>
                                    {!loading ? <img alt={company.name} style={{height: '2rem', verticalAlign: 'middle', marginRight: '1rem'}} src={company.image === 'eqwity' ? 'https://firebasestorage.googleapis.com/v0/b/eqwityapp.appspot.com/o/logos%2Feqwity.png?alt=media&token=943b6d12-1eee-4806-9e35-5cb222e43456' : `https://firebasestorage.googleapis.com/v0/b/eqwityapp.appspot.com/o/logos%2F${company.value}.png?alt=media&token=bb447700-0df9-4177-8719-ef64e64d70f4`}/> : <CircularProgress style={{color: '#f5f5f5'}}/>}
                                </TableCell>
                                <TableCell style={{fontFamily: 'helvetica', fontWeight: '100', fontSize: '1rem'}}>
                                    {!loading ? company.name : <CircularProgress style={{color: '#f5f5f5'}}/>}
                                </TableCell>
                                <TableCell style={{fontFamily: 'helvetica', fontWeight: '100', fontSize: '1rem'}} align="right">{!loading ? <NumberFormat displayType="text" value={company.private.customerLeverage} thousandSeparator={true}/> : <CircularProgress style={{color: '#f5f5f5'}}/>}</TableCell>
                                <TableCell style={{fontFamily: 'helvetica', fontWeight: '100', fontSize: '1rem'}} align="right">{!loading ? (company.keyMetric === "dollars" ? <NumberFormat displayType="text" value={company.private.dollarLeverage * 12} thousandSeparator={true} prefix={'$'}/> : '-') : <CircularProgress style={{color: '#f5f5f5'}}/>}</TableCell>
                            </TableRow>)})}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
            <Dialog open={newLeverageFormVisible} onClose={() => setNewLeverageFormVisibility(false)} aria-labelledby="form-dialog-title">
                {loading ? <CircularProgress style={{marginTop: '1rem', marginLeft: '1.5rem', color: '#444'}}/> : <DialogTitle id="form-dialog-title" style={{marginBottom: 0, paddingBottom: 0}}>Add Leverage</DialogTitle>}
                <DialogContent>
                    <DialogContentText style={{fontWeight: '200', fontFamily: 'Helvetica'}}>
                        If and when the time comes, we'll do our best to help offset costs and challenges
                        with changing or dropping providers. For example, if we as a collective decide to drop
                        Grocery Chain A, we will help offset costs to have your groceries delivered from another.
                    </DialogContentText>
                    {newCompanyFormVisible && <div>
                        <div style={{marginBottom: '1rem'}}>
                            <Typography variant="headline">Add New Company</Typography>
                        </div>
                        <TextField
                            style={{width: '100%', marginBottom: '1rem'}}
                            disabled={loading}
                            onChange={(event) => setNewCompanyName(event.target.value)}
                            value={newCompanyName}
                            variant="filled"
                            label="Company Name"
                        />
                        <FormControl variant="filled" style={{width: '100%', marginBottom: '1rem'}}>
                            <InputLabel>Category</InputLabel>
                            <Select
                                value={newCompanyCategory}
                                onChange={(event) => setNewCompanyCategory(event.target.value)}
                            >
                                {categories.map((category) => { return(
                                    <MenuItem key={category.value+"1"} value={category.value}>{category.name}</MenuItem>
                                )})}
                            </Select>
                        </FormControl>
                        <FormControl variant="filled" style={{width: '100%', marginBottom: '1rem'}}>
                            <InputLabel>Type</InputLabel>
                            <Select
                                value={newCompanyKeyMetric}
                                onChange={(event) => {setNewCompanyKeyMetric(event.target.value);}}
                            >
                                <MenuItem value="dollars">recurring / subscription</MenuItem>
                                <MenuItem value="customer">frequent customer</MenuItem>
                            </Select>
                        </FormControl>
                        {newCompanyKeyMetric === "dollars" && <TextField
                            style={{width: '100%'}}
                            disabled={loading}
                            onChange={(event) => setLeverageAmount(event.target.value)}
                            value={leverageAmount}
                            type="number"
                            variant="filled"
                            label="Dollars per Month"
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            helperText="(numbers only)"
                        />}
                    </div>}
                    {!newCompanyFormVisible && <div>
                        <FormControl variant="filled" style={{width: '100%', marginBottom: '1rem'}}>
                            <InputLabel>Category</InputLabel>
                            <Select
                                value={leverageCategory}
                                onChange={(event) => {setLeverageCategory(event.target.value)}}
                            >
                                {categories.map((category) => { return(
                                    <MenuItem key={category.value+"1"} value={category.value}>{category.name}</MenuItem>
                                )})}
                            </Select>
                        </FormControl>
                        {leverageCategory && <FormControl variant="filled" style={{width: '100%', marginBottom: '1rem'}}>
                            <InputLabel>Company</InputLabel>
                            <Select
                                value={leverageCompany}
                                onChange={(event) => setLeverageCompany(event.target.value)}
                            >
                                {companies.map((company) => {
                                    if (company.category === leverageCategory) {return <MenuItem key={company.id} value={company}>{company.name}</MenuItem>}
                                })}
                            </Select>
                        </FormControl>}
                        {leverageCompany && <FormControl variant="filled" style={{width: '100%', marginBottom: '1rem'}}>
                            <InputLabel>Type</InputLabel>
                            <Select
                                value={leverageType}
                                onChange={(event) => {setLeverageType(event.target.value);}}
                            >
                                <MenuItem value="dollars">recurring / subscription</MenuItem>
                                <MenuItem value="customer">frequent customer</MenuItem>
                            </Select>
                        </FormControl>}
                        {leverageType === "dollars" && <TextField
                            style={{width: '100%'}}
                            disabled={loading}
                            onChange={(event) => setLeverageAmount(event.target.value)}
                            value={leverageAmount}
                            type="number"
                            variant="filled"
                            label="Dollars per Month"
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            helperText="(numbers only)"
                        />}
                        <DialogContentText style={{fontWeight: '600', fontFamily: 'Helvetica'}}>
                            <div style={{marginRight: '1rem', marginBottom: '0.5rem'}}>Don't see something?</div>

                            <Button disabled={loading} size="small" variant="outlined" style={{borderColor: '#222', color: '#222'}} onClick={() => setNewCompanyFormVisibility(true)} color="default">
                                Add Company
                            </Button>
                        </DialogContentText>
                    </div>}
                </DialogContent>
                <DialogActions>
                    {newCompanyFormVisible && <Button disabled={loading} variant="outlined" style={{borderColor: '#222', color: '#222'}} onClick={() => setNewCompanyFormVisibility(false)} color="default">
                        View Existing Businesses
                    </Button>}
                    {(newCompanyFormVisible && newCompanyName && newCompanyCategory && newCompanyKeyMetric && (newCompanyKeyMetric === "dollars" ? leverageAmount > 0 : true) && (newCompanyKeyMetric === "customers" ? true : true)) && <Button disabled={loading} onClick={addCompany} style={{backgroundColor: '#000', color: '#f5f5f5', opacity: loading ? 0.5 : 1}} variant="contained">
                        Add
                    </Button>}
                    {!newCompanyFormVisible && <Button disabled={loading} onClick={() => setNewLeverageFormVisibility(false)} color="default">
                        Cancel
                    </Button>}
                    {(!newCompanyFormVisible && leverageCategory && leverageCompany && (leverageType === "dollars" ? leverageAmount > 0 : true) && (leverageType === "customers" ? true : true)) && <Button disabled={loading} onClick={addLever} style={{backgroundColor: '#000', color: '#f5f5f5', opacity: loading ? 0.5 : 1}} variant="contained">
                        Add
                    </Button>}
                </DialogActions>
            </Dialog>
            <Dialog open={voteFormVisible} onClose={() => setVoteFormVisibility(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title" style={{marginBottom: 0, paddingBottom: 0}}>Vote</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{fontWeight: '200', fontFamily: 'Helvetica'}}>
                        It's not time to vote, yet. We'll reach out once it is.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={() => setVoteFormVisibility(false)} color="default">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

export default withStyles(CSS)(Dashboard);
